import { template as template_11171fb7ca1d4824b7206ac95e50cd32 } from "@ember/template-compiler";
const FKControlMenuContainer = template_11171fb7ca1d4824b7206ac95e50cd32(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
