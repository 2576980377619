import { template as template_aa8327b393984da8afc4aea265ad6a43 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
export default class RenderGlimmerContainer extends Component {
    @service
    renderGlimmer;
    static{
        template_aa8327b393984da8afc4aea265ad6a43(`
    {{~#each this.renderGlimmer._registrations as |info|~}}
      {{~#in-element info.element insertBefore=null~}}
        <info.component
          @data={{info.data}}
          @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
        />
      {{~/in-element~}}
    {{~/each~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
